<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          cols="1"
          md="2"
          class="text-center"
        />
        <b-col
          cols="6"
          md="8"
          class="mb-2"
        >
          <div class="cardHie1">
            <b-tabs>
              <b-tab>
                <template #title>
                  <feather-icon icon="UserIcon" />
                  <span>IMPORT DOCTOR / EMPLOYEE</span>
                </template>
                <div>
                  <hr>
                  <b-row>
                    <b-col md="4">
                      <b-form-group
                        label="นำเข้า ข้อมูลแพทย์/พนักงาน"
                        label-for="mc-first-name"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="codeDocSri"
                            placeholder="Code แพทย์ / พนักงาน"
                            @keydown.enter="codeSearch"
                          />
                          <b-input-group-append>
                            <b-button
                              v-b-modal.modal-dark
                              variant="outline-primary"
                              @click="codeSearch"
                            >
                              ค้นหา
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" />
                    <b-col md="4" />
                  </b-row>
                </div>
              </b-tab>
              <b-tab active>
                <template #title>
                  <feather-icon icon="PlusIcon" />
                  <span>ADD USER</span>
                </template>
                <h2
                  style="color: rgb(115, 103, 240)"
                  class="mb-2 bt-2"
                />
                <hr>
                <b-row>
                  <b-col md="2">
                    <b-form-group
                      label="คำนำหน้า"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="title"
                        placeholder="คำนำหน้า"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="ชื่อ"
                      label-for="mc-first-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-first-name"
                          v-model="first_name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="First Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="นามสกุล"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="last_name"
                        placeholder="Last Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label-for="mc-email"
                      label="ประเภทผู้ใช้งาน"
                    >
                      <b-form-select
                        v-model="selected"
                        class="mb-1"
                        :options="options"
                        :state="selected === null ? false : true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="เบอร์โทร"
                      label-for="mc-city"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SmartphoneIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="hi-number"
                          v-model="phone"
                          type="number"
                          placeholder="เบอร์โทร"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label-for="mc-email"
                      label="Email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="hi-email"
                          v-model="email"
                          type="email"
                          placeholder="Email"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="ที่อยู่"
                      label-for="mc-company"
                    >
                      <div class="form-label-group">
                        <b-form-input
                          id="mc-company"
                          v-model="address"
                          placeholder="ที่อยู่"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col md="4" />
                  <b-col cols="8" />
                  <b-col md="4">
                    <b-form-group
                      label-for="mc-email"
                      label="Username Generator"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button
                            v-show="flag === false"
                            variant="outline-primary"
                            @click.prevent="UsernameGenerator"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="username"
                          placeholder=" - Click Button - "
                          disabled
                        >/>
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label-for="mc-email"
                      label="Password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LockIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="hi-password"
                          v-model="password"
                          type="password"
                          placeholder="Password"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <br>
                  </b-col>
                  <b-col cols="4" />
                  <b-col
                    cols="4"
                    class="text-center"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      @click.prevent="validationForm"
                    >
                      บันทึกข้อมูล
                    </b-button>
                  </b-col>
                  <b-col cols="4" />
                </b-row>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="text-center"
        />
      </b-row>
    </validation-observer>
    <b-modal
      v-show="InfoUser != []"
      id="modal-dark"
      hide-footer
      ok-only
      ok-variant="danger"
      ok-title="CLOSE"
      modal-class="modal-dark"
      size="lg"
      centered
      title="HIE USER"
    >
      <b-card>
        <b-row>
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group
              class="mb-0"
            />
          </b-col>
          <b-col
            md="4"
            sm="8"
            class="my-1"
          />
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            sm="12"
          >
            <b-table
              hover
              responsive
              :sticky-header="stickyHeader"
              :items="InfoUser"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              selectable
              :select-mode="selectMode"
              :head-variant="headVariant"
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <template #cell(grp)="data">
                <b-badge :variant="type[1][data.value]">
                  {{ type[0][data.value] }}
                </b-badge>
              </template>
              <template #cell(choose)="row">
                <b-button
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  v-b-modal.modal-dark
                  size="sm"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="info(row.item, row.index, $event.target)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template></b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: true,
      codeDocSri: '',
      InfoUser: [],
      title: '',
      first_name: '',
      last_name: '',
      fullNameDoc: '',
      codeDoc: '',
      docLicense: '',
      alertStatus: false,
      request_to: 'โรงพยาบาลมหาราชนครเชียงใหม่',
      value: '',
      emailValue: '',
      name: '',
      address: '',
      phone: '',
      username: '',
      password: '',
      confirmpassword: '',
      addUser_status: '',
      required,
      email,
      patient_title: '',
      patient_first_name: '',
      patient_last_name: '',
      patient_hn: '',
      patient_reason: '',
      patient_info_requested: '',
      selected: null,
      selected1: null,
      status_t: null,
      options: [
        { value: null, text: 'เลือกประเภทผู้ใช้งาน' },
        { value: '1', text: 'แพทย์' },
        { value: '2', text: 'พนักงานศูนย์ศรีพัฒน์' },
        { value: '3', text: 'พนักงานสวนดอก' },
        { value: '4', text: 'ญาติผู้ป่วย' },
        { value: '5', text: 'ผู้ป่วย' },
        { value: '6', text: 'บริษัทประกัน' },
      ],
      perPage: 10,
      headVariant: 'dark',
      selectMode: 'single',
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      status_transfersUser: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      flag: false,
      id_Bset: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'username', label: 'username', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'grp', label: 'type', sortable: true },
        'choose',
      ],
      type: [{
        1: 'แพทย์', 2: 'พนักงานศูนย์ศรีพัฒน์', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-warning', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    UsernameGenerator() {
      this.$http({
        url: 'api/User/newUser',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.username = response.data.username
        this.flag = true
        this.id_Bset = response.data.id
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
         this.addUser()
        }
      })
    },
    success() {
      this.$swal({
        title: 'ส่งคำขอสำเร็จ',
        text: 'ส่งคำขอเรียบร้อยแล้ว',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async addUser() {
      await this.$http({
        url: 'api/User/UpdateUser',
        method: 'post',
        data: {
          ID: this.id_Bset,
          Type: this.selected,
          Title: this.title,
          FName: this.first_name,
          LName: this.last_name,
          Address: this.address,
          Phone: this.phone,
          Email: this.email,
        },
      }).then(response => {
        this.addUser_status = response.data
      })
      await this.$http({
        url: 'api/User/UpdatePassword',
        method: 'post',
        data: {
          ID: this.id_Bset,
          Username: this.username,
          Password: this.password,
        },
      })
      await this.alertStatus_User()
    },
    async alertStatus_User() {
      if (this.addUser_status === 'ok') {
        await this.$swal({
          title: 'เพิ่มข้อมูลผู้ใช้สำเร็จ',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        await this.$router.go()
      } else {
        this.$swal({
          title: 'เพิ่มข้อมูลผู้ใช้ไม่สำเร็จ',
          text: ' กรุณาตรวจสอบหรือบันทึกข้อมูลใหม่',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    clearinfo() {
      this.location.reload()
    },
    async codeSearch() {
      await this.getDocInfo()
    },
    successDoc_Search() {
      this.$swal({
        title: `${this.codeDoc}`,
        text: `${this.fullNameDoc}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    errorDoc_Search() {
      this.$swal({
        title: 'ไม่พบข้อมูล',
        text: ' กรุณาตรวจสอบหรือใส่ข้อมูลใหม่',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async getDocInfo() {
      await this.$http({
        url: `api/User/searchUserSD/${this.codeDocSri}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.InfoUser = response.data
        this.totalRows = this.InfoUser.length
      })
      await this.addInfoType()
    },
    addInfoType() {
      let index = 0
      for (index; index < this.InfoUser.length; index += 1) {
        if (this.InfoUser[index].grp === 'doc') {
          this.InfoUser[index].grp = '1'
        } else {
          this.InfoUser[index].grp = '2'
        }
      }
    },
    docName() {
      let index = 0
      for (index; index < this.InfoDoc.length; index += 1) {
        if (this.InfoDoc[index].doctorCode === this.codeDocSri.toUpperCase()) {
          this.fullNameDoc = `${this.InfoDoc[index].title} ${this.InfoDoc[index].fName} ${this.InfoDoc[index].lName}`
          this.codeDoc = `${this.InfoDoc[index].doctorCode}`
          this.docLicense = `${this.InfoDoc[index].docLicense}`
          this.first_name = `${this.InfoDoc[index].fName}`
          this.last_name = `${this.InfoDoc[index].lName}`
          this.title = `${this.InfoDoc[index].title}`
          this.alertStatus = true
          break
        // eslint-disable-next-line no-empty
        } else {
          this.alertStatus = false
        }
      }
      this.$swal({
        title: `${this.codeDoc}`,
        text: `${this.fullNameDoc}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onRowSelected(items) {
      this.selected1 = items
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      await this.confirmButton(item.username)
    },
    async confirmButton(username) {
      await this.$swal({
        title: 'นำเข้าข้อมูลผู้ใช้หรือไม่?',
        text: 'คุณจะไม่สามารถย้อนกลับได้',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'นำเข้าข้อมูล',
        cancelButtonText: 'ไม่',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.transfersUser(username)
          this.$swal({
            icon: 'success',
            title: 'นำเข้าข้อมูลสำเร็จ',
            text: 'Your file has been success.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    alertStatusTransfers() {
      if (this.status_t === true) {
        this.$router.go()
      } else {
        this.error()
      }
    },
    error() {
      this.$swal({
        title: 'นำเข้าข้อมูลไม่สำเร็จ',
        text: ' กรุณาตรวจสอบข้อมูลหรืนำเข้าข้อมูลใหม่',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    CheckStatus() {
      if (this.status_transfersUser === 'SUCCESS_TRANSFER') {
        this.status_t = true
      } else {
        this.status_t = false
      }
    },
    async transfersUser(username) {
      await this.$http({
        url: `api/User/transfersUser/${username}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.status_transfersUser = response.data
      })
      await this.CheckStatus()
      await this.alertStatusTransfers()
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>
.btn-secondary {
    border-color: #006ef5 !important;
    background-color: #0073ff !important;
}
[dir] .btn-secondary:focus, [dir] .btn-secondary:active, [dir] .btn-secondary.active {
    background-color: #006ef5 !important;
}
.cardHie1 {
  border-radius: 20px;
  border: 2px solid rgb(241, 241, 241);
  background-color: rgb(255, 255, 255) !important;
  padding: 5%;
}
.label {
  color: #e71c1c;
  font-size: 0.857rem;
}
</style>
